import { Dispatch, SetStateAction, useEffect, useState } from "react";

import assert from "assert";

import MagicBell, {
  FloatingNotificationInbox,
  INotification,
} from "@magicbell/magicbell-react";
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import { User } from "firebase/auth";
import Link from "next/link";
import { useRouter } from "next/router";

import ServiceUpLogo from "shared/components/logos/ServiceUpLogo";
import ServiceUpMobileLogo from "shared/components/logos/ServiceUpMobileLogo";
import { useAuth } from "shared/providers/AuthProvider";
import { useSnackbar } from "shared/toast/SnackbarProvider";

import { Fleet } from "../../../../../backend/src/fleets/fleet.entity";
import { useFleets } from "../../providers/FleetProvider";
import AddFleetModal from "../modals/AddFleetModal";
import AddTeamModal from "../modals/AddTeamModal";

import AccountSwitcher from "./AccountSwitcher";
import DesktopViewAuthenticated from "./DesktopViewAuthenticated";
import MobileDrawer from "./MobileDrawer";

const isInsurancePortal = process.env.NEXT_PUBLIC_PROJECT_NAME === "insurance";

type MobileViewProps = {
  showModal: boolean;
  isAuthenticated: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onSubmit: (fleet: Fleet) => void;
  currentUser: User | null;
};

function MobileView({
  showModal,
  isAuthenticated,
  setShowModal,
  onSubmit,
  currentUser,
}: MobileViewProps) {
  const [open, setOpen] = useState(false);
  const { fleets, currentFleetId } = useFleets();
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  assert(
    process.env.NEXT_PUBLIC_MAGIC_BELL_API_KEY,
    "Magic Bell API key is missing"
  );

  const handleNotificationClick = (notification: INotification) => {
    router.push({
      pathname: `/request/${notification.topic}`,
      query: { fleetId: currentFleetId },
    });
  };

  return (
    <Stack
      width={"100%"}
      display="flex"
      gap={2}
      justifyContent={"space-between"}
      direction={"row"}
      alignItems={"center"}
    >
      <IconButton>
        <ServiceUpMobileLogo />
      </IconButton>
      <Box
        display={"flex"}
        flexGrow={{ md: 1, xs: 0.5 }}
        gap={2}
        justifyContent={{ md: "flex-end", xs: "space-between" }}
        alignItems="center"
      >
        {!!fleets?.length && (
          <AccountSwitcher
            label={isInsurancePortal ? "Team" : "Fleet"}
            fleets={
              isInsurancePortal
                ? fleets.filter((fleet) => fleet.isInsuranceFleet)
                : fleets
            }
            setShowModal={setShowModal}
          />
        )}
        <MagicBell
          apiKey={process.env.NEXT_PUBLIC_MAGIC_BELL_API_KEY}
          userEmail={currentUser?.email ?? ""}
          userExternalId={currentUser?.uid}
          locale="en"
          theme={{
            footer: { backgroundColor: "#FFFFFF", textColor: "#FFFFFF" },
          }}
        >
          {(props) => (
            <FloatingNotificationInbox
              width={isMobile ? 310 : 400}
              height={500}
              placement="top-start"
              hideArrow
              notificationPreferencesEnabled={false}
              onNotificationClick={handleNotificationClick}
              {...props}
            />
          )}
        </MagicBell>
        <IconButton
          size="large"
          edge="start"
          aria-label="menu"
          disableFocusRipple
          disableRipple
          sx={{ color: "#000000" }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <GridMenuIcon />
        </IconButton>
      </Box>

      {isInsurancePortal ? (
        <AddTeamModal
          open={showModal}
          onClose={() => setShowModal(false)}
          onAddTeamSuccess={onSubmit}
        />
      ) : (
        <AddFleetModal
          open={showModal}
          onClose={() => setShowModal(false)}
          onAddFleetSuccess={onSubmit}
        />
      )}

      <MobileDrawer
        isAuthenticated={isAuthenticated}
        openDrawer={open}
        setOpenDrawer={setOpen}
      />
    </Stack>
  );
}

export default function Navbar() {
  const router = useRouter();
  const { currentUser, logout } = useAuth();
  const [showAddFleetModal, setShowAddFleetModal] = useState(false);
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const {
    currentFleets,
    currentFleetId: fleetId,
    setCurrentFleetId,
    refetch,
  } = useFleets();
  const { toast } = useSnackbar();

  const onAddFleetSubmit = async (fleet: Fleet) => {
    if (!fleet) return;
    setShowAddFleetModal(false);
    await setCurrentFleetId(fleet.id);
    await refetch();
  };

  const onAddTeamSubmit = (fleet: Fleet) => {
    if (!fleet) return;
    setShowAddTeamModal(false);
    toast.success("Team created successfully");
  };

  const setShowModal = isInsurancePortal
    ? setShowAddTeamModal
    : setShowAddFleetModal;

  const showModal = isInsurancePortal ? showAddTeamModal : showAddFleetModal;
  const onSubmit = isInsurancePortal ? onAddTeamSubmit : onAddFleetSubmit;

  useEffect(() => {
    const checkFleetAndLogout = async () => {
      const isInsuranceFleet = currentFleets?.some(
        (fleet) => fleet.isInsuranceFleet
      );
      if (!isInsuranceFleet) return;

      if (isInsurancePortal !== isInsuranceFleet) {
        await logout();
        if (!process.env.NEXT_PUBLIC_PROJECT_URL) return;
        window.location.href = process.env.NEXT_PUBLIC_PROJECT_URL;
      }
    };

    checkFleetAndLogout();
  }, [currentFleets, logout, router]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: "background.default",
        py: 1,
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box display={{ xs: "none", lg: "flex" }} paddingRight={2}>
          <Link href={`/request?fleetId=${fleetId}`}>
            <ServiceUpLogo />
          </Link>
        </Box>
        <Box display="flex" width={"100%"} gap={{ xs: 2, lg: 0 }}>
          {isMobile ? (
            <MobileView
              isAuthenticated={currentUser !== null}
              showModal={showModal}
              setShowModal={setShowModal}
              onSubmit={onSubmit}
              currentUser={currentUser}
            />
          ) : (
            <DesktopViewAuthenticated currentPath={router.asPath} />
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
